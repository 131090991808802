
import { mapActions } from 'vuex';
export default {
  props: {
    titleHero: {
      type: String,
      default: null,
    },
    landingPage: {
      type: Object,
      required: false,
    },
  },
  computed: {
    getLanguageTags() {
      return this.landingPage ? this.landingPage : this.$t(`landingPage`);
    },
    getTitle() {
      return this.titleHero ? this.titleHero : this.$t(`landingPage.title`);
    },
    isOtherButtonAvailable() {
      return this.getQuizVariant === 'symptoms';
    },
    getVariantDesktopImage() {
      return this.$t(`landingPage.${this.getQuizVariant}`)?.desktopImage || 'landing-pages/lose-weight-women-new.webp';
    },
    getVariantMobileImage() {
      return (
        this.$t(`landingPage.${this.getQuizVariant}`)?.desktopImage || 'landing-pages/lose-weight-women-mobile-new.webp'
      );
    },
  },
  methods: {
    ...mapActions('quiz', ['pushQuizAnswerToTheStoreAction', 'setDefaultStateValue']),
    handleDefaultState(value) {
      this.pushQuizAnswerToTheStoreAction({ id: value, answer: value });
      this.setDefaultStateValue(value);
      this.$router.push('/quiz/start');
    },
  },
};
